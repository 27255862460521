<div class="paddedContent paddedTopFirst">   
    <div class="headerHolder"><h4>Website Privacy Notice</h4></div>
    <p>This Privacy Policy provides important information about your privacy in connection with your interactions with LifePort, Inc. and Enflite, Inc. ("we", "us" or "our") including your use of our websites. Please review it carefully.  If you provide or continue to provide your personal information to us or use this website, you agree to us collecting, holding, using and disclosing your personal information in accordance with this Privacy Policy and any subsequent amendments.</p>
    <h5>Our Commitment to Privacy</h5>
    <p>Your privacy is important to us. We are committed to complying with all applicable privacy laws and regulations.</p>
    <p>We strive to provide an environment in which our employees, customers, partners, and you trust us to handle personal information responsibly. We provide this Privacy Policy as part of our commitment to privacy by describing what personal information we collect, how we use it, how we protect it, and with whom we share it.</p>
    <h5>Scope of This Privacy Policy</h5>
    <p>This Privacy Policy applies to all visitors to our website.</p>
    <h5>What Information We Collect and How We Use It</h5>
    <p>While visiting our website, or in some instances you otherwise interact with us, you may be asked to provide personal information in order to accomplish the purpose of your visit. For example, if you are exploring career opportunities you may be asked to provide your email address, residence, education, work experience, and other personal information typically found on a résumé.  If you are a supplier you may be asked to identify the financial institution you use and provide account numbers. The specific information that you may choose to provide will depend on the nature of the transaction to be completed and is always voluntary. However, we may not be able to provide you with (or acquire your) goods and services if you do not provide us with the information we request.</p>
    <h5>How We Protect Your Information</h5>
    <p>We take reasonable steps (including physical, electronic, and managerial procedures) to safeguard and secure your personal information against loss, misuse, or unauthorized access. All personal information that we collect is protected using industry standard computer security and industrial security practices.</p>
    <h5>Cookies and Other Website Technical Considerations</h5>
    <p>Our website uses modern web application methods that result in the automatic collection and storage of certain data that some may consider to be personal or personally identifiable information. This includes your Internet Protocol or IP addresses, type of browser that you use, and other technical data about your computing environment. This information is stored in server logs that we maintain and review for the purpose of improving and supporting the secure operation of our website. We use “cookies” on our website to enhance the experience of our visitors, such as retaining user preferences between sessions. A cookie is a small text file that is stored on your computer. Cookies cannot be used to deliver viruses to your computer, they are uniquely assigned to you, and our cookies can only be read by our web servers. You have the ability to accept or decline cookies or, if you prefer, you can modify your web browser settings to decline all cookies or selectively decline cookies as they are offered. Our website may contain links to other websites. You should carefully review the privacy notices and practices of other websites, as we cannot control or be responsible for the privacy practices of sites not affiliated with us. For further details please see our <a routerLink="{{cookieInfoLink}}">Cookie Information Page</a>.</p>
    <h5>With Whom We Share Your Information</h5>
    <p>Personal information collected from or about you by us will not be shared with third parties for purposes other than in support of our operations and as necessary to facilitate the purpose for which it was collected. In some cases we may share the information with service providers retained by us to provide services on our behalf. These providers will use your information only to accomplish the task for which they have been retained.</p>
    <p>Your information may also be shared with other third parties under the following circumstances (i) if we are required to do so by law enforcement authorities or government agencies, or (ii) in connection with investigations or other efforts to prevent illegal activities or pertaining to public safety.</p>
    <h5>Children’s Privacy</h5>
    <p>Our websites are not designed with the intent to collect information from children under the age of 13. We ask that children under the age of 13 not submit any personal information to us while viewing our website. If you believe that we have collected information about a child under 13, please contact us at <a href="mailto:{{privacyEmail}}">{{privacyEmail}}</a> so that we may delete the information.</p>
    <h5>Updates to This Privacy Policy and to Your Information</h5>
    <p>We may update this Privacy Policy periodically to reflect changes in our practice. Updates will be dated and posted on our website. If you continue to provide us with personal information or use our website after this date you will be considered to have agreed to the updated Privacy Policy.</p>
    <p>We take reasonable steps to ensure that the personal information that we collect and hold is accurate, complete and up-to-date. However, we rely on you to advise us of any changes to your personal information. If you volunteered personal information to us and would like to update it, please visit the portion of this site where you submitted the information and follow the directions there to submit any changes.</p>
    <p>We will, on request, provide you with access to the personal information that we hold about you unless otherwise permitted or required by law.</p>
    <h5>How to Contact us or make a Complaint</h5>
    <p>If you have questions relating to this Privacy Policy, or for any questions you might have about our privacy practices, including any complaints please contact us at <a href="mailto:{{privacyEmail}}">{{privacyEmail}}</a>.</p>
</div>

<br style="clear:both;"/>

<div class="paddedContent">
  <app-contact-info></app-contact-info>
</div>