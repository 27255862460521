<app-top-picture 
  [imgLocation]="topImg.location" 
  [imgHeight]="topImg.height" 
  [imgPosition]="topImg.position" 
  [imgBgSize]="topImg.bgSize" 
  [bgColor]="topImg.bgColor" 
  [mainTitle]="topImg.mainTitle" 
  [secondTitle]="topImg.secondTitle">
</app-top-picture>
<app-navbar></app-navbar>
<div class="underNavNavSpacer"></div>
<div class="underNav" [style.margin-top]="topImg.height">
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
<app-cookie-consent></app-cookie-consent>
