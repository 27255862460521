import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { Equipment } from '../models';
import { equipmentMap } from '../constants';


//this is a typescript decorator that includes metadata for the compent. The selector is used to insert the component into the browser/UI; everything below is bundled within the component
@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html', //html that enables string interpolation where you can embed vars
  styleUrls: ['./equipment.component.scss', '../app.component.scss'] ///this is just a style sheet thats associated with the component. 
})
export class EquipmentComponent implements OnInit, OnDestroy { //this is just like any other class in OOP, but implements OnInit means do this stuff in the parenth when the component is created
  paramMap: Subscription;
  viewKey: string;
  equipment: Equipment;
  customLD: any;

  constructor( //constructors in angular are used to import services you want to use
    private connectorService: ConnectorService, 
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.paramMap = this.route.paramMap.subscribe((params: ParamMap) => {
      this.viewKey = params.get('key');
      this.equipment = equipmentMap[this.viewKey];

      // tslint:disable: quotemark
      this.customLD = {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": this.equipment.label,
        "sku": "Various",
        "mpn": "Various",
        "image": [
          "https://lifeport.com/assets/images/" + this.equipment.headerImage.location
        ],
        "description": this.equipment.mainText,
        "brand": {
          "@type": "Thing",
          "name": "LifePort / Enflite"
        }
      };
      // tslint:enable: quotemark

      setTimeout(() => {
        this.changeTopImg('eq' + this.viewKey);
      });
    });
  }

  changeTopImg(key) {
    console.log('Calling (changeTopImg) from Equipment Component with key = "' + key + '"');
    this.connectorService.changeTopImg(key);
  }

  ngOnDestroy() {
    this.paramMap.unsubscribe();
  }

}
