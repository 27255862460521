import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/home/home.component';
import { ContactComponent } from 'src/app/contact/contact.component';
import { EquipmentComponent } from 'src/app/equipment/equipment.component';
//import { DivisionComponent } from 'src/app/division/division.component';
import { PrivacyComponent } from 'src/app/privacy/privacy.component';
import { CookieComponent } from 'src/app/cookie/cookie.component';
import { CertificationComponent } from 'src/app/certification/certification.component';
import { EventsComponent } from 'src/app/events/events.component';
import { CareersComponent } from 'src/app/careers/careers.component';
import { SuppliersComponent } from 'src/app/suppliers/suppliers.component';
import { BrochuresComponent } from './brochures/brochures.component';

const routes: Routes = [
  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  { path: 'Home', component: HomeComponent, pathMatch: 'full' },
  { path: 'Brochures', component: BrochuresComponent, pathMatch: 'full' },
  { path: 'About/Privacy', component: PrivacyComponent, pathMatch: 'full' },
  { path: 'About/Cookies', component: CookieComponent, pathMatch: 'full' },
  { path: 'About/Certification', component: CertificationComponent, pathMatch: 'full' },
  { path: 'About/Events', component: EventsComponent, pathMatch: 'full' },
  { path: 'About/Careers', component: CareersComponent, pathMatch: 'full' },
  { path: 'About/SupplierInfo', component: SuppliersComponent, pathMatch: 'full' },
  { path: 'Contact/:key', component: ContactComponent, pathMatch: 'full' },
  { path: 'Equipment/:key', component: EquipmentComponent, pathMatch: 'full' },
  //{ path: 'Division/:key', component: DivisionComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
