import { Component, OnInit } from '@angular/core';
import { ConnectorService } from '../connector.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Suppliers Component');
      this.connectorService.changeTopImg('aboutSuppliers');
    });
  }
}
