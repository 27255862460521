import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Services & Top Level
import { ConnectorService } from './connector.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Shared Components
import { CarouselComponent } from './_carousel/carousel.component';
import { NavbarComponent } from './_navbar/navbar.component';
import { ContactInfoComponent } from './_contact-info/contact-info.component';
import { TopPictureComponent } from './_top-picture/top-picture.component';
import { TitlebarComponent } from './_titlebar/titlebar.component';
import { FooterComponent } from './_footer/footer.component';
import { CookieConsentComponent } from './_cookie-consent/cookie-consent.component';
import { JsonLdComponent } from './json-ld.component';

// Destination Components
import { ContactComponent } from './contact/contact.component';
import { EquipmentComponent } from './equipment/equipment.component';
//import { DivisionComponent } from './division/division.component';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CertificationComponent } from './certification/certification.component';
import { EventsComponent } from './events/events.component';
import { CookieComponent } from './cookie/cookie.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { CareersComponent } from './careers/careers.component';
import { BrochuresComponent } from './brochures/brochures.component';
import { CatalogComponent } from './catalog/catalog.component';


@NgModule({
  declarations: [
    AppComponent,
    CareersComponent,
    CarouselComponent,
    CertificationComponent,
    ContactComponent,
    ContactInfoComponent,
    CookieComponent,
    EquipmentComponent,
    EventsComponent,
    FooterComponent,
    HomeComponent,
    JsonLdComponent,
   // DivisionComponent,
    NavbarComponent,
    PrivacyComponent,
    SuppliersComponent,
    TitlebarComponent,
    TopPictureComponent,
    CookieConsentComponent,
    BrochuresComponent,
    CatalogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [ConnectorService],
  bootstrap: [AppComponent]
})
export class AppModule { }
