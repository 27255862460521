<nav class="navbar navbar-expand-lg navbar-dark fixed-top">
  <a class="navbar-brand" routerLink="Home">
    <div class="headerLogoImage" alt="Header Logo"></div>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="Home" [routerLinkActive]="['activeNav']">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="Brochures" [routerLinkActive]="['activeNav']">Brochures</a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" href="https://shop.lifeport.com" [routerLinkActive]="['activeNav']">Catalog</a>
      </li> -->
      <li *ngFor="let dropdown of dropdowns" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{dropdown.label}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <ng-container *ngFor="let navbarItem of dropdown.navbarItems" >
            <a *ngIf="navbarItem.navbarUrl.baseKey !== 'self'" class="dropdown-item" href="{{navbarItem.navbarUrl.url}}">{{navbarItem.label}}</a>
            <a *ngIf="navbarItem.navbarUrl.baseKey === 'self'" class="dropdown-item" routerLink="{{navbarItem.navbarUrl.url}}" [routerLinkActive]="['activeNav']">{{navbarItem.label}}</a>
          </ng-container>
        </div>
      </li>
    </ul>
    <!-- <a title="Find us on Facebook" target="_blank" href="https://www.facebook.com/LifePortWA"><img src="../../assets/images/social/f_logo_RGB-White_72.png" style="height: 28px; margin-right: 20px;"/></a> -->
    <a title="Find us on LinkedIn" target="_blank" href="https://www.linkedin.com/company/enflite-inc."><img src="../../assets/images/social/LinkedInLogo.svg" style="height: 28px; margin-right: 20px;"/></a>
    <!-- <a class="button navbar-right requestQuoteButton" [routerLink]="requestQuoteLink">Request a Quote</a> -->
    <br style="clear:both;" />
  </div>
</nav>