import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { requestQuoteLink, dropdowns } from '../constants';
import * as $ from 'jquery';

@Component ({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss', '../app.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewChecked {
  dropdowns = dropdowns;
  requestQuoteLink = requestQuoteLink;
  constructor() { }
  ngOnInit() {

  }

  ngAfterViewChecked() {
    $('.dropdown-item').parent('.dropdown-menu').parent('.nav-item').children('.nav-link').removeClass('activeNav');
    $('.dropdown-item.activeNav').parent('.dropdown-menu').parent('.nav-item').children('.nav-link').addClass('activeNav');
  }
}



