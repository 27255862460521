import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { Subscription } from 'rxjs';
import { ParamMap, ActivatedRoute } from '@angular/router';

interface ContactFormSetup {
  label: string;
  url: string;
}

class ContactForm {
  label: string;
  url: string;
  constructor(setup: ContactFormSetup) {
    this.label = setup.label;
    this.url = setup.url;
  }
}

const contactFormMap = {
  //'MFA': new ContactForm({label: 'Customer Feedback', url: 'https://le204.infusionsoft.app/app/form/2ccbb6a50161f37b6db219ce11dfae02'}), ///NEED TO UPDATE FOR ENFLITE MIGRATION
  'Question': new ContactForm({label: 'Customer Inquiry', url: 'https://le204.infusionsoft.app/app/form/lifeport_website_contactform'}), ///NEED TO UPDATE FOR ENFLITE MIGRATION
  'RFQ': new ContactForm({label: 'Quote Request', url: 'https://le204.infusionsoft.app/app/form/lifeport_website_contactform'}),
  'Sales': new ContactForm({label: 'Quote Request', url: 'https://stage.enflite.com/'}), ///NEED TO UPDATE FOR ENFLITE MIGRATION
};

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  paramMap: Subscription;
  viewKey: string;
  contactForm: ContactForm;
  redirectTimer: any;

  constructor(
    private connectorService: ConnectorService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.paramMap = this.route.paramMap.subscribe((params: ParamMap) => {
      this.viewKey = params.get('key');
      this.contactForm = contactFormMap[this.viewKey];

      setTimeout(() => {
        console.log('Calling (changeTopImg) from Contact Component');
        this.connectorService.changeTopImg('contact');
      });

      this.redirectTimer = setTimeout(() => {
        location.href = this.contactForm.url;
      }, 2000);
    });
  }

  ngOnDestroy() {
    this.paramMap.unsubscribe();
    clearTimeout(this.redirectTimer);
    this.redirectTimer = undefined;
  }
}
