import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { jsonLD_defs } from './jsonLD_defs';

@Component({
    selector: 'app-json-ld',
    template: '<div [innerHTML]="html"></div>',
    styles: []
    })

export class JsonLdComponent implements OnInit {
    @Input() seoKey?: string;
    @Input() customLD?: any;
    html: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
        if (this.seoKey) {
            this.html = this.getSafeHTML(jsonLD_defs[this.seoKey]);
        } else if (this.customLD) {
            this.html = this.getSafeHTML(this.customLD);
        }
    }

    getSafeHTML(jsonLD: any): SafeHtml {
        const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        // escape / to prevent script tag in JSON
        const html = `<script type="application/ld+json">${json}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
