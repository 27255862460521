<div class="paddedContent"> 
    <div class="headerHolder"><h4>What are Cookies?</h4></div>  
  <p>Our website uses cookies. A cookie is a small file of letters and numbers that a website puts on your computer. We use ‘analytical’ cookies. They allow us to recognize and count the number of visitors and to see how visitors move around the site when they’re using it. This helps us to ensure that users are finding what they need easily.</p>
  <p>Some of the cookies we use are from third party services that help make your experience on our website better.  Some of them allow us to identify the topics and subjects visitors find most interesting. </p>
  <p>We also use a cookie to remember whether you have agreed to use cookies.</p>  
  <div class="headerHolder"><h4>Cookies we use</h4></div>
  <table class="custom" style="width: 100%">
      <tbody>
          <tr>
              <th>Cookie</th>
              <th>Description</th>
              <th>Expiration</th>
          </tr>
          <tr>
              <td>_ga</td>
              <td>Google Analytics - Used to distinguish users.</td>
              <td>2 years from set/update</td>
          </tr>
          <tr>
              <td>_gid</td>
              <td>Google Analytics - Used to distinguish users.</td>
              <td>24 hours from set/update</td>
          </tr>
          <tr>
              <td>_gat</td>
              <td>Google Analytics - Used to throttle request rate. If Google Analytics is deployed via Google Tag Manager, this cookie will be named _dc_gtm_[property-id].</td>
              <td>1 minute from set/update</td>
          </tr>
          <tr>
              <td>Google Analytics Cookies</td>
              <td>We use analytics.js, a Google Analytics tool. The primary Google Analytics cookies are listed above; however you can find a full list of possible cookies generated by analytics.js <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">here</a>.</td>
              <td></td>
          </tr>
          <tr>
              <td>cb-enabled</td>
              <td>This cookie is saved if you agree to use cookies. This way we don't have to show the cookie banner every time you visit our site.</td>
              <td>1 year from set/update</td>
          </tr>
      </tbody>
  </table>
  <div class="headerHolder"><h4>Disabling Cookies</h4></div>
  <p>Most web browsers allow some control of most cookies through the browser settings. To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a> or the support page for your web browser.</p>
  <p>Additional information on cookies and online privacy can be found on the <a href="https://ico.org.uk/" target="_blank">Information Commissioner’s Office website</a>.</p> 
</div>


