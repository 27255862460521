// tslint:disable: quotemark

export const jsonLD_defs = {
    lifeportInfo: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://lifeport.com",
        "name": "LifePort",
        "telephone": "+1-360-225-1212",
        "faxNumber": "+1-360-225-9390",
        "email": "solutions@lifeport.com",
        "logo": "https://lifeport.com/assets/images/logos/lifeport-red-logo.png",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "1610 Heritage Street",
            "addressLocality": "Woodland",
            "addressRegion": "WA",
            "postalCode": "98674",
            "addressCountry": "US"
        }
    },
    enfliteInfo: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://enflite.com",
        "name": "Enflite",
        "telephone": "+1-512-868-3399",
        "faxNumber": "+1-512-868-3320",
        "email": "solutions@enflite.com", //need to change email
        "logo": "https://lifeport.com/assets/images/logos/enflite-red-logo.png", //MAY NEED TO CHANGE LOGO REFERENCE
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "105 Cooperative Way",
            "addressLocality": "Georgetown",
            "addressRegion": "TX",
            "postalCode": "78626",
            "addressCountry": "US"
        }
    }
};
