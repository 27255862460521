import { Component, OnInit } from '@angular/core';
import { customerServiceEmail, requestQuoteLink } from '../constants';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss', '../app.component.scss']
})
export class ContactInfoComponent implements OnInit {

  customerServiceEmail = customerServiceEmail;
  requestQuoteLink = requestQuoteLink;
  constructor() { }

  ngOnInit() {
  }

}
