// DIVISIONS
interface DivisionSetup {
  label: string;
  mainText: string;
  headerImage: TopImg;
  relatedEquipmentKeys: string[];
}

export class Division {
  label: string;
  mainText: string;
  headerImage: TopImg;
  relatedEquipmentKeys: string[];

  constructor(setup: DivisionSetup) {
    this.label = setup.label;
    this.mainText = setup.mainText;
    this.headerImage = setup.headerImage;
    this.relatedEquipmentKeys = setup.relatedEquipmentKeys;
  }
}

// EQUIPMENT
interface EquipmentSetup {
  label: string;
  mainText: string;
  detailText: string;
  headerImage: TopImg;
  lowerHTML?: string;
}

export class Equipment {
  label: string;
  mainText: string;
  detailText: string;
  headerImage: TopImg;
  lowerHTML: string;

  constructor(setup: EquipmentSetup) {
    this.label = setup.label;
    this.mainText = setup.mainText;
    this.detailText = setup.detailText;
    this.headerImage = setup.headerImage;
    this.lowerHTML = setup.lowerHTML;
  }
}

// NAVBAR
export class NavbarUrl {
  url: string;
  page: string;
  baseKey: string;
  constructor(baseKey: string, page: string, args?: any) {
    // configuration
    const urlBaseMap = {
      'self': '',
    };

    // set basic params
    const urlBase = urlBaseMap[baseKey];
    this.page = page;
    this.baseKey = baseKey;
    this.url = urlBase + page;
  }
}

export class NavbarItem {
  label: string;
  navbarUrl: NavbarUrl;
  constructor(label: string, navbarUrl: NavbarUrl) {
    this.label = label;
    this.navbarUrl = navbarUrl;
  }
}

export class NavbarDropdown {
  label: string;
  navbarItems: NavbarItem[];
  constructor(label: string, navbarItems: NavbarItem[]) {
    this.label = label;
    this.navbarItems = navbarItems;
  }
}

// TOP IMAGE
interface TopImgInterface {
  mainTitle: string;
  secondTitle?: string;
  location?: string;
  height?: string;
  position?: string;
  bgSize?: string;
  bgColor?: string;
}

export class TopImg {
  mainTitle: string;
  secondTitle: string;
  location: string;
  height: string;
  position: string;
  bgSize: string;
  bgColor: string;

  constructor(setup: TopImgInterface) {
    this.mainTitle = setup.mainTitle;
    this.secondTitle = setup.secondTitle || '';
    this.location = setup.location || 'blackBg.jpg';
    this.height = setup.height || '60vh';
    this.position = setup.position || 'left 50% top 50%';
    this.bgSize = setup.bgSize || 'cover';
    this.bgColor = setup.bgColor || 'black';
  }
}

// CAROUSEL
export class CarouselItem {
  text: string;
  imageLocation: string;
  routerLink: string;

  constructor(text, imageLocation, routerLink) {
    this.text = text;
    this.imageLocation = imageLocation;
    this.routerLink = routerLink;
  }
}
