import { Component, OnInit } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { careersEmail } from '../constants';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  careersEmail = careersEmail;
  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Careers Component');
      this.connectorService.changeTopImg('aboutCareers');
    });
  }
}
