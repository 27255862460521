<div class="paddedContent">
  <div class="boldLeftTxt">{{equipment.mainText}}</div>
  <div class="rightTxt smallTxt">{{equipment.detailText}}</div>
  <br style="clear:both"/>
  <div class="smallTxt" [innerHTML]="equipment.lowerHTML"></div>
</div>

<app-json-ld [customLD]="customLD"></app-json-ld>

<!-- <div class="paddedContent">
  <div class="headerHolder"><h4>Related Divisions: {{equipment.label}}</h4></div>
</div>

<app-carousel
[label]="'Related Divisions'">
</app-carousel> -->

<div class="paddedContent">
  <app-contact-info></app-contact-info>
</div>
