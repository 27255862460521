<section class="carousel-wrapper">
  <ul class="carousel" [@animationCounterChange]="animationCounter">
    <li *ngFor="let item of items" 
      class="carousel-inner" 
      [style.background-image]="'url(assets/images/' + item.imageLocation + ')'">
      <div class="carouselHover" [routerLink]="item.routerLink">Click for more info...</div>
      <div class="carouselBanner">{{item.text}}</div>
      
    </li>
  </ul>
</section>
<div class="carousel-control-wrapper">
  <div class="carousel-control-inner">
    <span class='carousel-control-arrow' (click)="prev()">⇦</span> {{label}} ({{items.length}}) <span class='carousel-control-arrow' (click)="next()">⇨</span>
  </div>
</div>