import { Component, OnInit } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { cookieInfoLink, privacyEmail } from '../constants';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  cookieInfoLink = cookieInfoLink;
  privacyEmail = privacyEmail;
  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Privacy Component');
      this.connectorService.changeTopImg('aboutPrivacy');
    });
  }

}
