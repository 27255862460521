import { NavbarDropdown, NavbarItem, NavbarUrl, Division, Equipment, TopImg, CarouselItem } from './models';
// tslint:disable: max-line-length
// tslint:disable: forin

function sortByKey(array, key) {
  return array.sort(function(a, b) {
      const x = a[key]; const y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

// Emails
export const privacyEmail = 'solutions@enflite.com'; //need to change email
export const careersEmail = 'jobs@lifeport.com';
export const customerServiceEmail = 'solutions@enflite.com'; //need to change email

// Links
export const cookieInfoLink = '/About/Cookies';
export const requestQuoteLink = '/Contact/RFQ';

// Divisions
////////////////////////////////////////////
const divisionTopImgHeight = '75vh';
export const divisionMap = {
  //When you see "for (const divisionKey in divisionMap)"-see carouselMaker f(x) below-the divsionKey is the title stated prior to the "new Divsion({", divisionKey is essentially the name of the object, unclear how tf 
  Air_Medical: new Division({
    label: 'Air Medical',
    mainText: 'For over 25 years LifePort has developed and manufactured industry leading medical interiors for Fixed and Rotor wing aircraft. Our product teams design and manufacture products to fulfill our customers\' unique mission requirements.',
    headerImage: new TopImg({mainTitle: 'Division: Air Medical', location: '/division/Air_Medical.jpg', position: 'bottom 35% left 50%', height: divisionTopImgHeight, }),
    relatedEquipmentKeys: ['ALS', 'MedicalCabinetry', 'EpiShuttle', 'Flooring', 'Consoles', 'PLS', 'Mission_Seating', 'Litters', 'Stretcher'],
  }),
  Armor: new Division({
    label: 'Armor',
    mainText: 'LifePort pioneers aircraft solutions that have been utilized in some of the most challenging environments that the world has to offer. Our products currently serve dozens of military organizations around the world.',
    headerImage: new TopImg({mainTitle: 'Division: Armor', location: '/division/Armor.jpg', position: 'top 40% left 50%', height: divisionTopImgHeight, }),
    relatedEquipmentKeys: ['Armor', 'Transparent_Armor'],
  }),
  Enflite: new Division({
    label: 'Enflite',
    mainText: 'Enflite specializes in Pocket Doors, Tables, Lift Mechanisms, and Galley Equipment. All of these products can be adapted to support a wide range of configurations typical of VIP programs.',
    headerImage: new TopImg({mainTitle: 'Division: Enflite', location: '/division/Enflite.jpg', position: 'bottom 65% left 50%', height: divisionTopImgHeight, }),
    relatedEquipmentKeys: ['Galley', 'Mechanisms_and_Mounts', 'Pocket_Doors', 'Tables'],
  }),
  Interiors: new Division({
    label: 'Interiors',
    mainText: 'LifePort systems provide aircraft solutions that have been utilized in some of the most challenging environments that the world has to offer. We use this experience to ensure that our catalog of products meets the most stringent mission requirements.',
    headerImage: new TopImg({mainTitle: 'Division: Interiors', location: '/division/Interiors.jpg', position: 'top 50% left 15%', height: divisionTopImgHeight, }),
    relatedEquipmentKeys: ['Cabinetry', 'Consoles', 'Vip_Seating'],
  }),
};

// Equipment
////////////////////////////////////////////
const equipmentTopImgHeight = '75vh';
export const equipmentMap = {
  // ALS: new Equipment({
  //   label: 'Advanced Life Support',
  //   mainText: 'Designed for a wide range of mission profiles, our ALS systems offer low-maintenance standard packages that include oxygen, compressed air, vacuum, and electrical inverters.',
  //   detailText: 'Our portfolio ranges from integrated line replaceable units (LRUs) that exclusively equip Gulfstream G-Vs to multi-mission systems for the Airbus H125 Astar. LifePort systems are designed to be compact, maximizing the available space in the aircraft. We also have the internal capability to design and certify liquid or gaseous oxygen systems for extended range aircraft or multiple patients that need to be supported at the same time.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Advanced Life Support (ALS)',
  //     location: 'equipment/ALS_Air_Medical.jpg',
  //     position: 'bottom 85% left 50%',
  //     height: equipmentTopImgHeight,
  //   })
  // }),
  // Armor: new Equipment({
  //   label: 'Ballistic Protection',
  //   mainText: 'LifePort offers one of the lightest, most proven multi-hit armor solutions available.',
  //   detailText: 'We take great pride in providing you with custom armor solutions for Fixed and Rotor wing aircraft, as well as non-aviation projects. Our ability to design, manufacture, integrate, and certify armor systems for occupant and aircraft critical components results in tailored solutions to meet  mission requirements. Our systems are combat proven to be some of the lightest, most ballistically capable systems on the market. All LifePort armor systems are protected by LifeGard coating, which increases the life and durability of the protected armor system.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Ballistic Protection Systems',
  //     position: 'bottom 40% left',
  //     location: 'equipment/armor_header.jpg',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // Cabinetry: new Equipment({
  //   label: 'Cabinetry',
  //   mainText: 'LifePort manufactures custom cabinetry and interior components for the world’s most prestigious executive aircraft.',
  //   detailText: 'LifePort has experience integrating products into a diverse portfolio of aircraft and supporting a range of customer missions. Having delivered interiors for small helicopters as well as large transport-category aircraft, LifePort engineers are accustomed to turning initial concepts into comprehensive interiors.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Custom Cabinetry',
  //     location: 'equipment/Cabinetry_Interiors.jpg',
  //     position: 'bottom 20% right 40%',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // MedicalCabinetry: new Equipment({
  //   label: 'Medical Cabinets',
  //   mainText: 'LifePort manufactures custom medical cabinetry and interior components for the world’s most prestigious executive aircraft.',
  //   detailText: 'LifePort has experience integrating products into a diverse portfolio of and medical aircraft and supporting a range of customer missions. Having develped medical cabinet interiors for small helicopters as well as large transport-category aircraft.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Medical Cabinetry',
  //     location: 'equipment/MedicalCabinetry_Air_Medical.jpg',
  //     position: 'bottom 20% right 40%',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // Consoles: new Equipment({
  //   label: 'Operator Consoles',
  //   mainText: 'LifePort manufactures special mission consoles that are customized based on mission requirements and integrated into the aircraft.',
  //   detailText: 'Ranging from basic to complex designs, our consoles have been utilized as one-off designs as well as repeat builds.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Operator Consoles',
  //     location: 'equipment/consoles_header.jpg',
  //     position: 'top 85% left 50%',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // EpiShuttle: new Equipment({
  //   label: 'EpiGuard EpiShuttle',
  //   mainText: 'LifePort will be leveraging expertise and market penetration within air ambulance and broader aerospace while partnering with EpiGuard’s proven patient isolation solution to support the fight against COVID-19',
  //   detailText: 'The EpiShuttle by EpiGuard is a single-patient isolation and transport system, designed to provide maximum patient safety and comfort while allowing critical care and treatment to be performed. The EpiShuttle is designed to protect both the environment and patient from contamination.',
  //   lowerHTML: `
  //   <hr/><b>WOODLAND, WA, April 16, 2020</b> – LifePort announced today that they have signed an agreement with EpiGuard (Fredrikstad, Norway) to distribute the EpiShuttle in the United States and Canada. LifePort will be leveraging expertise and market penetration within air ambulance and broader aerospace while partnering with EpiGuard’s proven patient isolation solution to support the fight against COVID-19.
  //   <br/></br>
  //   LifePort will design, engineer, and manufacture components that allow the EpiShuttle to be secured in a wide variety of transportation vehicles. Aviation and ground applications are designed to enable a seamless interface while providing a secure method of attaching the EpiShuttle in the vehicle. Through LifePort, the EpiShuttle is currently registered with both the FDA and Health Canada, and will be accompanied by a structural engineering report for the interface brackets.
  //   <br/></br>
  //   The EpiShuttle by EpiGuard is a single-patient isolation and transport system, designed to provide maximum patient safety and comfort while allowing critical care and treatment to be performed. The EpiShuttle is designed to protect both the environment and patient from contamination.
  //   <br/></br>
  //   “Both crew and aircraft are protected against contamination with the patient placed in an EpiShuttle. At the same time, the patient can receive lifesaving treatment. An ambulance usually requires 2-4 hours of disinfection between every contagious transport, and with an entire helicopter, it takes almost a day. Because the EpiShuttle is underpressurized and sealed it reduces the need for disinfection, saving valuable time and resources. Saving one and protecting everyone, the EpiShuttle is ready to deploy in North-America” says CEO of EpiGuard, Ellen Cathrine Andersen.
  //   <br/></br>
  //   “I am very excited to reach an agreement that leverages the sales, technical and manufacturing capabilities of EpiGuard and LifePort, which will result in increased product availability to the US and Canadian markets” says Jason Darley, CEO of LifePort.  “I have no doubt that our partnership will increase the safety and functionality of our products in the transport of infected or potentially infected patients.” 
  //   “We chose to distribute the EpiShuttle in the US and Canada through LifePort because of their strong market presence, relevant users’ proven confidence and most of all because of the depth of knowledge and expertise we find at LifePort. We are certain that LifePorts’ understanding of the functionalities and benefits of the EpiShuttle, combined with their knowledge of the North-American market, makes for a great partnership. The EpiShuttle is already in service with the Royal British Air Force, the Royal Norwegian Air Force and the Royal Danish Air Force, and with LifePort on board, I am confident that the EpiShuttle will save and protect North-Americans as well” says CEO of EpiGuard, Ellen Cathrine Andersen.
  //   <br/></br>
  //   <u>For more information, please feel free to contact LifePort or EpiGuard directly:</u>
  //   <ul>
  //   <li>LifePort General Phone number: (360) 225-1212 solutions@Lifeport.com</li>
  //   <li>EpiGuard General Phone number: +47.934.22.325 info@epiguard.com</li>
  //   </ul>
  //   <b>About LifePort and Enflite</b>
  //   <br/>
  //   Headquartered in Woodland, Washington and Georgetown, TX, LifePort and Enflite are recognized as industry leaders in designing, manufacturing, and certifying innovative equipment for all ranges or aircraft. For over 25 years, LifePort and Enflite have provided innovative interior solutions for both civil and military customers around the world.  
  //   <br/></br>
  //   <b>About EpiGuard</b>
  //   <br/>
  //   EpiGuard is a Norwegian company established in 2015 providing better solutions for safe transportation of contagious patients. Our team of medical experts and engineers developed the EpiShuttle based on analysis and clinical first-hand experience from previous global epidemics. Our medical background ranges from intensive care, infectious diseases, internal medicine and anesthesiology, and transport medicine. EpiShuttle improves patient safety and care, the safety for health care providers, reduce costs and increase operational efficiency. Saving one – protecting everyone.
  //   <br/></br>
  //   `,
  //   headerImage: new TopImg({
  //     mainTitle: 'EpiGuard EpiShuttle',
  //     location: 'equipment/EpiShuttle_Header.jpg',
  //     position: 'bottom',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // Flooring: new Equipment({
  //   label: 'Flooring',
  //   mainText: 'Designed to perform in the harshest environments, LifePort flooring has proven to be a valuable addition to any special mission interior.',
  //   detailText: 'LifePort floor coverings are manufactured using one-piece construction, providing a seamless, sealed floor formed to the aircraft decking. Flooring systems are designed with substrates and fluid dams to accommodate a wide range of missions and interior arrangements.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Flooring',
  //     location: 'equipment/flooring_header.jpg',
  //     position: 'top',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  Galley: new Equipment({
    label: 'Galley',
    mainText: 'Enflite galley products have been delivered as integrated components, as well as line replaceable units in support of Executive, VIP, and Head of State aircraft.',
    detailText: 'Our galley product line includes even-flow convection ovens, coffee makers, refrigerators, chillers, freezers, and combination units.',
    headerImage: new TopImg({
      mainTitle: 'Equipment: Galley',
      location: 'equipment/galley_header.jpg',
      position: 'top',
      height: equipmentTopImgHeight,
    }),
  }),
  // Litters: new Equipment({
  //   label: 'Stacking Litters',
  //   mainText: 'LifePort Stacking Litter Systems are in service with both Rotor and Fixed wing operators around the world.',
  //   detailText: 'The system is engineered and manufactured for military and commercial MEDEVAC, SAR, and utility missions.  The Rotor Wing system is in service with a wide variety of operators flying a range of missions.  The system is available in two, three, and four patient configurations depending on the aircraft type. The Fixed Wing System is in service with aircraft ranging from the Dornier 328 to the KC-135R.  The SLS product family has been rigorously tested to meet both commercial and military certification requirements, including TSO C39b, DO-160E, and MIL-STD-810G. ',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Stacking Litter Systems',
  //     location: 'equipment/litters_header.jpg',
  //     position: 'top 10% left',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  Mechanisms_and_Mounts: new Equipment({
    label: 'Mechanisms and Mounts',
    mainText: 'Enflite’s cabin mechanisms are used by aircraft designers to create a fluid and seamless interaction between passengers and aircraft systems.',
    detailText: 'Mechanisms are customized to fit a variety of displays, utilizing today’s latest accessory equipment and media.  They can be mounted on credenzas, side ledges, divan armrests, and between bulkheads. ',
    headerImage: new TopImg({
      mainTitle: 'Equipment: Cabin Mechanisms and Mounts',
      location: 'equipment/Mechanisms_and_Mounts_Enflite.jpg',
      bgSize: 'contain',
      height: equipmentTopImgHeight,
    }),
  }),
  // PLS: new Equipment({
  //   label: 'Powered Loading Systems',
  //   mainText: 'Designed to integrate with each unique loading condition, LifePort systems help by reducing or eliminating the attendant’s need to lift the patient into the aircraft.',
  //   detailText: 'From King Air 200 and Pilatus PC-12 to Gulfstream G550 and Falcon 7x, LifePort Powered Loading Systems are designed to equip a wide range of popular executive, VIP, and MEDEVAC aircraft.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Powered Loading Systems',
  //     location: 'equipment/PLS_Air_Medical.jpg',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  Pocket_Doors: new Equipment({
    label: 'Pocket Doors',
    mainText: 'Enflite provides some of the most reliable and technologically-advanced pocket doors available in the industry.  Both manual and electric products have been delivered to a wide range of aircraft OEMs and completion centers as integral interior components.',
    detailText: 'Our pocket doors are recognized by customers for their ease of installation and maintenance, as well as flawless operation and superior durability.  With over 20 years spent refining the product, Enflite pocket doors have become the standard against which all other doors are judged.',
    headerImage: new TopImg({
      mainTitle: 'Equipment: Pocket Doors',
      location: 'equipment/Pocket_Doors_Enflite.jpg',
      position: 'top',
      height: equipmentTopImgHeight,
    }),
  }),
  // Mission_Seating: new Equipment({
  //   label: 'Mission Seating',
  //   mainText: 'LifePort’s adaptable  product line uses the latest technology to achieve industry-leading levels of comfort, safety, quality, and durability.',
  //   detailText: 'Our extensive product catalog ranges from multi-axis 21G slide and swivel seats to single-place dynamic seating modules that can be grouped together to form unique configurations.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Seating',
  //     location: 'equipment/Mission_Seating_Air_Medical.jpg',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // Vip_Seating: new Equipment({
  //   label: 'VIP Seating',
  //   mainText: 'LifePort’s custom divan and seating product line uses the latest technology to achieve industry-leading levels of comfort, safety, quality, and durability.',
  //   detailText: 'Our extensive product catalog ranges from multi-axis 21G slide and swivel seats to single-place dynamic seating modules that can be grouped together to form unique configurations.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Seating',
  //     location: 'equipment/Vip_Seating_Interiors.jpg',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  // Stretcher: new Equipment({
  //   label: 'Stretchers',
  //   mainText: 'The flagship product - the Patient Loading Utility System (PLUS) – is viewed as the standard by which all multi-mission medical systems are judged.',
  //   detailText: 'Our Fixed wing, multi-mission medical interiors are built around the Patient Loading Utility System (PLUS), which includes an Advanced Life Support base unit, manual loading system, and AeroSled stretcher.  Our Rotor wing system is built around the MedDeck patient handling platform, which includes the MedPak ALS module, an attachment plate, trolley, receiving deck, and AeroSled stretcher. LifePort stretcher systems are designed to be lightweight, user-friendly and provide exceptional durability over years of extensive use.',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Stretcher Systems',
  //     location: 'equipment/stretcher_header.jpg',
  //     position: 'bottom 15% left',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
  Tables: new Equipment({
    label: 'Tables',
    mainText: 'Enflite manufactures a wide range of innovative table mechanisms for executive, VIP, and Head of State aircraft.',
    detailText: 'From single-leg pedestal tables, to sidewall tables, and custom dining or conference tables of virtually any size, Enflite products can be provided unfinished or as turn-key units including custom plating and composite tops. Whether an electric or manual option, our tables meet the highest ergonomic, durability, and reliability standards while remaining lightweight and intuitive to operate.',
    headerImage: new TopImg({
      mainTitle: 'Equipment: Tables',
      location: 'equipment/tables_header.jpg',
      position: 'bottom 10% left',
      height: equipmentTopImgHeight,
    }),
  }),
  // Transparent_Armor: new Equipment({
  //   label: 'Transparent Armor',
  //   mainText: 'Our revolutionary compound-curved Transparent Armor adds ballistic protection without compromising vision.',
  //   detailText: 'LifePort\'s armor transparencies were developed in direct cooperation with industry partners and operators of military aircraft around the world. Our systems can be customized to address any ballistic threat and integrated to meet all mission requirements. ',
  //   headerImage: new TopImg({
  //     mainTitle: 'Equipment: Transparent Armor',
  //     position: 'bottom 100% left 100%',
  //     location: 'equipment/Transparent_Armor_Armor.jpg',
  //     height: equipmentTopImgHeight,
  //   }),
  // }),
};

// Navbar
////////////////////////////////////////////
// generate dropdowns from maps
// let divisionNavbarDropdowns: NavbarItem[] = [];
// for (const divisionKey in divisionMap) {
//   const currentDivision = divisionMap[divisionKey];
//   divisionNavbarDropdowns.push(new NavbarItem(currentDivision.label, new NavbarUrl('self', '/Division/' + divisionKey)));
// }
let equipmentNavbarDropdowns: NavbarItem[] = [];
for (const equipmentKey in equipmentMap) {
  const currentEquipment = equipmentMap[equipmentKey];
  equipmentNavbarDropdowns.push(new NavbarItem(currentEquipment.label, new NavbarUrl('self', '/Equipment/' + equipmentKey)));
}
// sort generated dropdowns by alphabetical label
//divisionNavbarDropdowns = sortByKey(divisionNavbarDropdowns, 'label');
equipmentNavbarDropdowns = sortByKey(equipmentNavbarDropdowns, 'label');
// prepare dropdowns for export to navbar
export const dropdowns = [
  new NavbarDropdown('About', [
    new NavbarItem('Certification', new NavbarUrl('self', '/About/Certification')),
    new NavbarItem('Events', new NavbarUrl('self', '/About/Events')),
    new NavbarItem('Careers', new NavbarUrl('self', '/About/Careers')),
    new NavbarItem('Information for Suppliers', new NavbarUrl('self', '/About/SupplierInfo')),
  ]),
  //new NavbarDropdown('Divisions', divisionNavbarDropdowns),
  new NavbarDropdown('Equipment', equipmentNavbarDropdowns),
  //new NavbarDropdown('Contact', [
    //new NavbarItem('Ask a Question', new NavbarUrl('self', '/Contact/Question')),
    //new NavbarItem('Customer Feedback', new NavbarUrl('self', '/Contact/MFA')), REMOVED AT REQUEST OF 
    //new NavbarItem('Request a Quote', new NavbarUrl('self', '/Contact/RFQ')),
    //new NavbarItem('Sales Team', new NavbarUrl('self', '/Contact/Sales')),
  //]),
];

// Top Image
////////////////////////////////////////////
const minHeight = '62px';
const homeHeight = '85vh';
// define non-generated top images
export const topImgMap = {
  brochures: new TopImg({
    mainTitle: 'Brochures',
    height: minHeight
  }),
  aboutCareers: new TopImg({
    mainTitle: 'Working at Enflite',
    location: '/careers/collage.jpg',
    bgSize: 'contain',
    bgColor: 'white',
    height: '700px',
    position: 'right 50% bottom 50%'
  }),
  aboutCertification: new TopImg({
    mainTitle: 'Certification',
    location: 'certification_header.jpg',
  }),
  aboutCookies: new TopImg({
    mainTitle: 'Cookies',
    height: minHeight
  }),
  aboutEvents: new TopImg({
    mainTitle: 'Events',
    location: 'events_header.jpg',
  }),
  aboutPrivacy: new TopImg({
    mainTitle: 'Privacy',
    height: minHeight
  }),
  aboutSuppliers: new TopImg({
    mainTitle: 'Information for Suppliers',
    height: minHeight
  }),
  contact: new TopImg({
    mainTitle: 'Contact Us',
    height: minHeight
  }),
  homeSAR: new TopImg({
    mainTitle: 'Search and Rescue Solutions',
    secondTitle: 'Stacking litter systems, advanced life support, stretcher systems, flooring, seating, and more',
    location: 'homeSlideshow/sar.jpg',
    height: homeHeight,
  }),
  homeMED: new TopImg({
    mainTitle: 'Air Medical Solutions',
    secondTitle: 'Over 25 years of innovative, industry leading medical interiors for Fixed and Rotor wing aircraft',
    location: 'homeSlideshow/med.jpg',
    height: homeHeight,
    position: 'left 50% bottom 33%',
  }),
  homeMIL: new TopImg({
    mainTitle: 'Military Solutions',
    secondTitle: 'Lightweight Ballistic Protection Systems, MEDEVAC, and CASEVAC systems',
    location: 'homeSlideshow/mil.jpg',
    height: homeHeight,
    position: 'left 10% top 50%',
  }),
  homeLAW: new TopImg({
    mainTitle: 'Law Enforcement Solutions',
    secondTitle: 'Over 25 years of innovative, industry leading medical interiors for Fixed and Rotor wing aircraft',
    location: 'homeSlideshow/law.jpg',
    height: homeHeight,
  }),
  homeUTILITY: new TopImg({
    mainTitle: 'Utility Solutions',
    secondTitle: 'Over 25 years of innovative, industry leading medical interiors for Fixed and Rotor wing aircraft',
    location: 'homeSlideshow/utility.jpg',
    height: homeHeight,
  }),
  homeVIP: new TopImg({
    mainTitle: 'VIP Solutions',
    secondTitle: 'Over 25 years of innovative, industry leading interiors for Fixed and Rotor wing aircraft',
    location: 'homeSlideshow/vip.jpg',
    height: homeHeight,
    position: 'left 50% bottom 1%',
  }),
  loading: new TopImg({
    mainTitle: 'Aircraft Solutions',
  }),
};
// generate remaining top images based on maps
for (const divisionKey in divisionMap) {
  topImgMap['division' + divisionKey] = divisionMap[divisionKey]['headerImage'];
}
for (const equipmentKey in equipmentMap) {
  topImgMap['eq' + equipmentKey] = equipmentMap[equipmentKey]['headerImage'];
}

// Carousel
////////////////////////////////////////////
export function carouselMaker(componentName: string, viewKey?: string) {
  console.log('calling (carouselMaker) with componentName = "' + componentName + '" and viewKey = "' + viewKey + '"');
  const carouselList = [];
  // Default Division Data for all Divisions
  if (componentName === 'Home') {
    const relatedDivisions = [];
    for (const divisionKey in divisionMap) { // for each of the objects in the division Map Array above, push the object name (i.e. divisionKey) to the empty set for "relatedDivisions"
        relatedDivisions.push(divisionKey);
    }
    for (const divisionKey of relatedDivisions) {
      carouselList.push(
        new CarouselItem(
          divisionMap[divisionKey]['label'] + '  Division', //this satisfies the "text:" parameter for the CarouselItem class found in models.ts
          'division/' + divisionKey + '.jpg', //this satisfies the "imagelocation:" parameter for the CarouselItem class found in models.ts, not sure how where the first part of path comes in though, the full location path is src/assets/images/division/Xfilename.jpg, only the divison/filename.jpg part of the path is provided here.
          '/Division/' + divisionKey//this satisfies the "routerLink:" parameter for the CarouselItem class found in models.ts, the lifeport.com is the "base" router link it think, so this effectively creates the lifeport.com/Division/Air_Medical (or any other division name) url for the pushed image. 
        )
      );
    }
  } else if (componentName === 'Division') {
    for (const equipmentKey of divisionMap[viewKey]['relatedEquipmentKeys']) {
      carouselList.push(
        new CarouselItem(
          equipmentMap[equipmentKey]['label'],
          'equipment/' + equipmentKey + '_' + viewKey + '.jpg',
          '/Equipment/' + equipmentKey
        )
      );
    }
  } 
  // else if (componentName === 'Equipment') {
  //   const relatedDivisions = [];
  //   for (const divisionKey in divisionMap) {
  //     console.log(divisionMap[divisionKey]['relatedEquipmentKeys']);
  //     // have to use indexOf for IE compatibility. Wasn't able to figure out a working polyfil for array.includes
  //     if (divisionMap[divisionKey]['relatedEquipmentKeys'].indexOf(viewKey) >= 0) {
  //       relatedDivisions.push(divisionKey);
  //     }
  //   }
  //   console.log(relatedDivisions);
  //   for (const divisionKey of relatedDivisions) {
  //     carouselList.push(
  //       new CarouselItem(
  //         divisionMap[divisionKey]['label'] + ' Division',
  //         'division/' + divisionKey + '.jpg',
  //         //'equipment/' + viewKey + '_' + divisionKey + '.jpg',
  //         '/Division/' + divisionKey
  //       )
  //     );
  //   }
  // }
  return carouselList;
}


