

<div class="paddedContent">
  <!-- <div style="width: 100%; background-color: white; padding-top: 10px;">
    <img src="../../assets/images/30-years-banner-2k.png" style="width: 100%; max-width: 1500px; display: block; margin: auto;">
  </div> -->
  <div class="boldLeftTxt">For over 25 years Enflite has provided innovative, cutting edge interior solutions for VIP aircraft</div>
  <div class="rightTxt smallTxt">We have built a world-wide reputation in the aerospace industry by designing, manufacturing, and certifying innovative equipment for all ranges of fixed and rotor wing aircraft - then supporting our customers throughout the product's life cycle.</div>
  <br style="clear: both;"/>
  
  <!-- <div class="headerHolder"><h4>Our Divisions</h4></div> -->
</div>

<!-- <app-carousel
  [label]="'Divisions'">
</app-carousel> -->

<div class="paddedContent">
  <app-contact-info></app-contact-info>
</div>

<app-json-ld [seoKey]="'lifeportInfo'"></app-json-ld>
<app-json-ld [seoKey]="'enfliteInfo'"></app-json-ld>