import { Component, OnInit } from '@angular/core';
import { ConnectorService } from '../connector.service';

@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit {

  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Certification Component');
      this.connectorService.changeTopImg('aboutCertification');
    });
  }
}
