<div class="paddedContent">
    <div class="headerHolder"></div>
    <h3>
      Product information brochures below are sorted alphabetically by Aircraft. Please contact our sales team if you have any questions, or to request marketing material for products not listed here.
    </h3>
    <div *ngFor="let aircraft of aircraftKeys">     
      <div class="headerHolder">
        <h4>{{aircraft}}</h4>
      </div>
      <div class="brochureHolder" *ngFor="let brochure of brochureListsByAircraft[aircraft]">
        <a href="/assets/documents/brochures/{{brochure.fileName}}" target="_blank">{{brochure.title}}</a>
        <div class='brochureDescription'>{{brochure.description}}</div> 
      </div>
      <br style="clear:both" />
    </div>
  </div>
  
  <div class="paddedContent">
    <app-contact-info></app-contact-info>
  </div>