// tslint:disable: max-line-length
import { Component, OnInit } from '@angular/core';
import { ConnectorService } from '../connector.service';

class Event {
  dateRange: string;
  year: string;
  label: string;
  location: string;
  website: string;
  prettywebsite: string;

  constructor(dateRange: string, year: number, eventLabel: string, eventLocation: string, website: string) {
    this.dateRange = dateRange;
    this.year = year.toString();
    this.label = eventLabel;
    this.location = eventLocation;
    this.website = website;
    this.prettywebsite = website.replace(/(^\w+:|^)\/\//, '');
  }
}

//const hai = new Event('January 28-30', 2020, 'Helicopter Association International (HAI)', 'Anaheim, CA', 'https://www.rotor.org');
//const aircraftinteriorsexpo = new Event('March 31 - April 2', 2020, 'Aircraft Interiors Expo (AIX)', 'Hamburg, Germany', 'https://www.aircraftinteriorsexpo.com');
//const quada = new Event('April 22-24', 2020, 'Army Aviation Association of America (AAAA)', 'Nashville, TN', 'https://www.quad-a.org');
const nbaa = new Event('October 12-14', 2021, 'National Business Aviation Association (NBAA)', 'Las Vegas, NV', 'https://nbaa.org');
//const amtc = new Event('November 2-4', 2020, 'Air Medical Transport Conference (AMTC)', 'Nashville, TN', 'https://aams.org/events/amtc');

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  events = [
    //hai,
    //aircraftinteriorsexpo,
    //quada,
    nbaa,
    //amtc
  ];

  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Events Component');
      this.connectorService.changeTopImg('aboutEvents');
    });
  }
}
