import { Component, OnInit } from '@angular/core';
import { ConnectorService } from '../connector.service';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Cookie Component');
      this.connectorService.changeTopImg('aboutCookies');
    });
  }
}
