<div class='flexCenter'>
  <a class='buttonLink' href='https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=C3F5DE9DE84F6E89AA22E8DAEEA499A9&fromClientSide=true' target='_blank'>Join our team</a>
</div>

<div class='spacerMedium'></div>

<div class='mission'>
  Enflite is always seeking high-performance, qualified professionals to join our team.  With 25 years in the industry, we pride ourselves as being one of the leading manufacturing facilities in the area.
  <br/><br/>
  We have built a worldwide reputation in the aerospace industry by designing, manufacturing, and certifying innovative equipment for all ranges of fixed and rotor wing aircraft.
</div>
<div class='preStory' style="width: 100%">
<div class='preStoryText'><br/>Our Core Values are...</div>
</div>
<div class='careersStoryHolder'>
  <div class='careersStoryItem'>
    <div class='storyPic'></div>
    <div class='storyHeader'>Culture</div>
    <div class='storyText'>Enflite was founded on the core belief that our customers and employees are our most valuable asset. We are a recognized leader in our industry, dedicated to quality in every aspect of our business.</div>
    <br style='clear: both'/> 
    <hr/>
    <div class='storyPic'></div>
    <div class='storyHeader'>Accountability</div>
    <div class='storyText'>Our teams are service-oriented with integrity, ambition, and commitment toward a common goal of excellence.</div>
    <br style='clear: both'/> 
    <br style='clear: both'/> 
    <hr/>
    <div class='storyPic'></div>
    <div class='storyHeader'>Innovation</div>
    <div class='storyText'>Our successful employees thrive in an entrepreneurial atmosphere that is committed to new product development through cutting edge innovation.</div>
    <br style='clear: both'/> 
    <br style='clear: both'/> 
  </div>
</div>
<div class='mission'>
  At Enflite, we value employees who embrace change, innovation, safety, and are continuously looking for ways to improve how we do business.
  <br/><br/>
  Come work for a respected industry leader providing innovative, cutting edge interior solutions for both civil and military aircraft.
</div>


