import { Component, OnInit } from '@angular/core';
import { ConnectorService } from './connector.service';
import { topImgMap } from './constants';
import { TopImg } from './models';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  topImg: TopImg = topImgMap.loading;

  constructor(
    private connectorService: ConnectorService,
    private router: Router,
  ) { }

  ngOnInit() {
    // subscribe to change top image in order to change top image from any component
    this.connectorService.changeTopImage$.subscribe(
      message => {
          console.log('Subscription to changeTopImage$ triggered at App Component with message = "' + message + '"');
          this.changeTopImg(message);
      }
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });

  }

  changeTopImg(key) {
    this.topImg = topImgMap[key];
  }
}
