<div class="headerHolder"><h4>Contact Information</h4></div>
<div class="contactInfoHolder">
    <!-- <p style="float: left; padding: 16px;">
        <strong>LifePort </strong>
        <br />1610 Heritage Street, Woodland, WA 98674
        <br />Telephone: +1 (360) 225-1212 
        <br />Fax (Main): +1 (360) 225-9390
    </p> -->
    <p style="float: left; padding: 16px;">
        <strong>Enflite </strong>
        <br />105 Cooperative Way, Georgetown, TX 78626
        <br />Telephone: +1 (512) 868-3399
        <br />Fax (Main): +1 (512) 868-3320
    </p>
    <p style="float: left; padding: 16px;">
        <strong>Links </strong>
        <br /><a href="mailto:{{customerServiceEmail}}">Email The Team</a>
        <!-- <br /><a [routerLink]="requestQuoteLink">Request a Quote</a> -->
    </p>
    <br style="clear: both;"/>
</div> 

