import { Component } from '@angular/core';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
  isConsented = false;
  COOKIE_CONSENT = 'cb-enabled';
  COOKIE_CONSENT_EXPIRE_DAYS = 365;

  constructor() {
      this.isConsented = this.getCookie(this.COOKIE_CONSENT) === '1';
  }

  private getCookie(name: string) {
      const ca: Array<string> = document.cookie.split(';');
      const caLen: number = ca.length;
      const cookieName = `${name}=`;
      let c: string;

      for (let i = 0; i < caLen; i += 1) {
          c = ca[i].replace(/^\s+/g, '');
          if (c.indexOf(cookieName) === 0) {
              return c.substring(cookieName.length, c.length);
          }
      }
      return '';
  }

  private deleteCookie(name) {
      this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
      const d: Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      const expires = `expires=${d.toUTCString()}`;
      const cpath: string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  private consent(isConsent: boolean, e: any) {
      console.log('calling (consent) from Cookie Consent Component');
      if (!isConsent) {
          return this.isConsented;
      } else if (isConsent) {
          this.deleteCookie(this.COOKIE_CONSENT);
          this.setCookie(this.COOKIE_CONSENT, '1', this.COOKIE_CONSENT_EXPIRE_DAYS);
          this.isConsented = true;
          e.preventDefault();
      }
  }
}
