<div class="topPicture" 
    [style.background-image]="'url(/assets/images/' + imgLocation + ')'" 
    [style.background-position]="imgPosition" 
    [style.background-size]="imgBgSize"
    [style.background-color]="bgColor"  
    [style.height]="imgHeight">
    <div>
        <app-titlebar [title]="mainTitle"></app-titlebar>
    </div>
    <div style="position: absolute; bottom: 0px;">
        <app-titlebar *ngIf="secondTitle || imgLocation !== 'blackBg.jpg'" [title]="secondTitle"></app-titlebar>
    </div>
</div>