import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConnectorService {

  // observable source
  private _changeTopImg = new Subject<string>();
  private _fillCarousel = new Subject<string>();

  // observable stream
  changeTopImage$ = this._changeTopImg.asObservable();
  fillCarousel$ = this._fillCarousel.asObservable();

  changeTopImg(string) {
    console.log('Observable triggered in (changeTopImg) from Connector Service with key = "' + string + '"');
    this._changeTopImg.next(string);
  }

  fillCarousel(string) {
    console.log('Observable triggered in (fillCarousel) from Connector Service with key = "' + string + '"');
    this._fillCarousel.next(string);
  }

  constructor() { }
}
