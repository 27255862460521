<div class="paddedContent">
    <div class="headerHolder"><h4>Trade Shows and Events</h4></div>
    <table class="custom" style="margin:20px 0px; width: 100%;">
      <thead>
          <tr>
              <th>Event Date</th>
              <th>Description</th>
              <th>Location</th>
              <th>Website</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor='let event of events'>
              <td>{{event.dateRange}}, {{event.year}}</td>
              <td>{{event.label}}</td>
              <td>{{event.location}}</td>
              <td><a href="{{event.website}}" target="_blank">{{event.prettywebsite}}</a></td>
          </tr>
      </tbody>
  </table>    
</div>

<div class="paddedContent">   
  <app-contact-info></app-contact-info>
</div>
  