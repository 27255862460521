import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-top-picture',
  templateUrl: './top-picture.component.html',
  styleUrls: ['./top-picture.component.scss', '../app.component.scss']
})
export class TopPictureComponent implements OnInit, OnChanges {
  // THIS IS ALL SENT FROM THE APP COMPONENT BECAUSE THAT WAY IT CAN ADJUST THE HEIGHT OF EVERYTHING UNDER THE PICTURE SIMULTANEOUSLY
  @Input() imgLocation: string;
  @Input() imgHeight: string;
  @Input() imgPosition: string;
  @Input() imgBgSize: string;
  @Input() bgColor: string;
  @Input() mainTitle: string;
  @Input() secondTitle: string;

  constructor(
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle( 'Enflite | ' + this.mainTitle );
  }

  ngOnChanges() {
    this.titleService.setTitle( 'Enflite | ' + this.mainTitle );
  }

}



