import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConnectorService } from '../connector.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', '../app.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  homeSlideshowKeys = [ 'homeVIP']; //'homeMED', 'homeSAR', 'homeUTILITY', 'homeMIL', 'homeLAW',
  slideshowIteration = 0;
  slideshowTimer: any;
  constructor(private connectorService: ConnectorService) { }

  ngOnInit() {
    setTimeout(() => {
      this.changeTopImg(this.homeSlideshowKeys[0]);
    });

    this.slideshowTimer = setInterval(() => {
      this.slideshowIteration++;
      this.changeTopImg(this.homeSlideshowKeys[this.slideshowIteration % this.homeSlideshowKeys.length]);
    }, 15000);
  }

  changeTopImg(key) {
    console.log('Calling (changeTopImg) from Home Component with key = "' + key + '"');
    this.connectorService.changeTopImg(key);
    clearInterval(this.slideshowTimer);
    this.slideshowTimer = setInterval(() => {
      this.slideshowIteration++;
      this.changeTopImg(this.homeSlideshowKeys[this.slideshowIteration % this.homeSlideshowKeys.length]);
    }, 15000);
  }

  ngOnDestroy() {
    this.changeTopImg('loading');
    clearInterval(this.slideshowTimer);
    this.slideshowTimer = undefined;
  }

}
