import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { animate, state, style, transition, trigger, query, stagger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { CarouselItem } from '../models';
import { carouselMaker } from '../constants';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  animations: [
    trigger('animationCounterChange', [
      transition(':increment', [
        query('.carousel-inner', [
          style({ transform: 'translateX(340px)' }),
          stagger(-10, [
            animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
          ])
        ]),
      ]),
      transition(':decrement', [
        query('.carousel-inner', [
          style({ transform: 'translateX(-340px)' }),
          stagger(-10, [
            animate('300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' }))
          ])
        ]),
      ]),
  ])],
})

export class CarouselComponent implements OnInit, OnDestroy {

  @Input() label: string;
  animationCounter = 0;
  items: CarouselItem[];
  paramMap: Subscription;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.paramMap = this.route.paramMap.subscribe((params: ParamMap) => {
      // Create Carousel Based on Component and Keys
      const currentComponentName = this.route.snapshot.url[0].path;
      const currentViewKey = params.get('key') ? params.get('key') : 'None';
      console.log('Current Component Name for Carousel: ' + currentComponentName);
      this.items = carouselMaker(currentComponentName, currentViewKey);
    });
  }

  ngOnDestroy() {
    this.paramMap.unsubscribe();
  }

  next() {
    console.log('(prev) called on Carousel Compoment');
    this.animationCounter++;
    console.log('Animation Counter: ' + this.animationCounter);
    const first = this.items.shift();
    this.items.push(first);
  }

  prev() {
    console.log('(next) called on Carousel Compoment');
    this.animationCounter--;
    console.log('Animation Counter: ' + this.animationCounter);
    const last = this.items.pop();
    this.items.unshift(last);
  }

}



