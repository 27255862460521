<div class="paddedContent">
  <div class="headerHolder"></div>
  <h3>
    Founded in 1993, Enflite is a world leader in the design, manufacture and certification of a wide range of VIP products for the aviation industry.
    Today, Enflite provides customized interior solutions for any aircraft and any mission.
  </h3>
  <div class="headerHolder"><h4>Certificates and Approvals</h4></div>
  <ul>
      <li><a href="/assets/documents/certifications/ENF ISO9001 AS9100.pdf" target="_blank">Enflite ISO 9001:2015 and AS9100:2016</a></li>
      <li><a href="/assets/documents/certifications/ENF Part 145.pdf" target="_blank">Enflite FAA Part 145 Repair Station</a></li>
      <li><a href="/assets/documents/certifications/Enflite EASA 145.6299 Certificate.pdf" target="_blank">EASA approved Part 145 Maintenance Organization</a></li>


      <!-- <li><a href="/assets/documents/certifications/LPI_AS9100.pdf" target="_blank">LifePort Interiors ISO 9001:2015 and AS9100: 2016</a></li> -->
      
      <!-- <li><a href="/assets/documents/certifications/LPSM_AS9100.pdf" target="_blank">LifePort Special Missions ISO 9001:2015 and AS9100: 2016</a></li>
      <li><a href="/assets/documents/certifications/LPSM_ISO_13484.pdf" target="_blank">LifePort Special Missions ISO 13485:2016</a></li>
      
      <li><a href="/assets/documents/certifications/LFP Part 145.pdf" target="_blank">LifePort FAA Part 145 Repair Station</a></li> -->
      <li>Parts Manufacturing Authority (PMA)</li>
      <!-- <li>More than 90 individual Supplemental Type Certificates (STC)</li>
      <li>More than 40 model-based Technical Standard Order (TSO) approvals</li> -->
    </ul>
</div>

<div class="paddedContent">
  <app-contact-info></app-contact-info>
</div>