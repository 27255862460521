import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { ConnectorService } from '../connector.service';

// tslint:disable: max-line-length

class Brochure {
  fileName: string;
  title: string;
  description: string;

  constructor(fileName: string, title: string, description: string) {
    this.fileName = fileName;
    this.title = title;
    this.description = description;
  }
}

const brochureListsByAircraft = {
  'Multi-Aircraft': [
    //new Brochure('Multi_MissionSeat.pdf', 'Mission Seat', 'Mission Seat is a true multi-mission seat for 9G, 16, G and 21G aircraft for medical attendant seating, executive seating and utility seating.  Medical Attendant Mission Seat rotates 360 degree; tracks forward, aft and laterally; available with armrests; lumbar support; vertical adjustment. Medical Attendant seating that allows for improved patient access and crew comfort for all missions.  Mission Seat is shipped with TSO.'),
    
    new Brochure('EF_2019_AutoDep_SW_Table_GS650_SEP19.pdf', 'Auto Deploy Sidewall Table', 'Next generation sidewall table mechanism with fully mechanical auto deploy operation.'),
    
  //   new Brochure('Aircraft_Divan_SS_D3.pdf', 'Aircraft Divans', 'LifePort’s custom divan and seating product line uses technology to achieve an industry-leading level of comfort, safety, quality, and durability. With products ranging from slouching or bunking divans, to fully articulating beds and dynamic seats, LifePort is the choice for both Head of State and VIP aircraft programs worldwide. LifePort’s extensive product catalog ranges from multi-axis 21G slide and swivel seats to single-place dynamic seating modules that can be grouped together to form unique configurations.'),
    
  //   new Brochure('Aircraft_Seating.pdf', 'Aircraft Seating', 'LifePort’s custom divan and seating product line uses technology to achieve an industry-leading level of comfort, safety, quality, and durability. With products ranging from slouching or bunking divans, to fully articulating beds and dynamic seats, LifePort is the choice for both Head of State and VIP aircraft programs worldwide. LifePort’s extensive product catalog ranges from multi-axis 21G slide and swivel seats to single-place dynamic seating modules that can be grouped together to form unique configurations.'),

  //   new Brochure('Ballistic_Protection_System.pdf', 'Ballistic Protection System', 'LifePort specializes in the design, integration and certification of lightweight armor solutions for both rotary-wing and fixed aircraft.'),

  //   new Brochure('Cabinets_SS_NOV2019.pdf', 'Cabinetry', 'LifePort manufactures custom cabinetry and interior components for the world’s most prestigious executive transport aircraft. LifePort has experience integrating these components into a diverse portfolio of aircraft and satisfying a range of customer mission requirements. Having delivered interiors for small helicopters as well as large transport-category aircraft, LifePort engineers are accustomed to turning initial concepts into comprehensive interiors. No matter the type of aircraft or mission, LifePort has a solution.'),

    // new Brochure('Composites_LP.pdf', 'Composites', 'LifePort’s composites capabilities are structured to support stringent program needs, from design and development through certification and full rate manufacturing. We can handle a wide range of projects from one off quick-turn parts to complex designs that have to be right the first time.'),

    // new Brochure('Dassault_7X_8X_IMS.pdf', 'Dassult Integrated Medical System', 'LifePort’s integrated medical system provides a comprehensive medical suite that requires little to no aircraft modification. After the initial installation, this medical system can be installed or removed without the use of hand tools. The medical base unit and accessory arch provide redundant services, attendant access, and patient comfort that ensures the system is suitable for dedicated or multi-mission operation over extended flights.'),
        
    // new Brochure('EpiShuttle_EpiGuard_DS and Tech Sheet Combined.pdf', 'EpiShuttle', 'The EpiShuttle is a single-patient isolation and transport system, designed to provide maximum patient safety and comfort while allowing critical care and treatment to be performed. The award-winning design was developed with direct input and guidance from clinical experts, medics, and patients.'),

    // new Brochure('Galley_Comp.pdf', 'Galleys', 'LifePort Interiors specializes in the design, engineering and production of interior monuments for all ranges of aircraft including business jets and helicopters. We have completed multiple ship sets of unique galley designs for large aircraft. Additionally, we have completed a wide variety of refreshment centers and storage cabinets for business jets and rotor craft. Individual galley products have been developed and delivered for both military and civilian VIP customers.'),

    // new Brochure('Stanchion_Litter_System.pdf', 'Stanchion Litter System (SLS)', 'Capable of flying medical patients on platforms regardless of transport litter type — integrated restraints.'),

    // new Brochure('OSL_Adapter_SLS.pdf', 'SLS Over Size Litter (OSL) Adapter', 'Provides the only approved system to secure the Over Size Litter (OSL).'),

    // new Brochure('SLS_Oxygen_Adapter.pdf', 'SLS Oxygen Cylinder Mount', 'Provides a simplified interface for securing “H” Cylinders for extended duration missions.'),

    // new Brochure('TLSS_DS.pdf', 'Triple Litter Stacking System', 'The system is engineered and manufactured for military and commercial MEDEVAC, SAR, and utility missions. The Rotor Wing system is in service with a wide variety of operators flying a range of missions. The system is available in two, three, and four patient configurations depending on the aircraft type.'),

    // new Brochure('Med_Util_Flooring_DS.pdf', 'Medical & Utility Flooring', 'LifePort’s LifeGard Medical Flooring System (MedFloor) provides protection to the aircraft floor when you need it most. Our lightweight LifeGard coating seals and protects, making it the premier solution in medevac and multi-mission configured aircraft.'),

    new Brochure('EF_2019_Bar_Stools_SS_FNL.pdf', 'Bar Stools', 'Custom bar stools featuring hi-lo operation, track and swivel or fixed height configurations.'),
    new Brochure('CoffeeMakerWithFillPort.pdf', 'Coffee Maker with Fill Port', 'Enflite’s new coffee maker is designed around ease of operation with a front fill port that allows the user to select the type of water they prefer for brewing. Brews 54oz of coffee in approximately 8 minutes.'),
    new Brochure('EF_2019_C5_Fridge_SS_SEPT2019.pdf', 'C-5 Refrigerator', 'Large refrigerator and freezer combination unit designed and approved for military applications.'),
    
    // new Brochure('LP_2019_AC_Divan_SS_D3.pdf', 'Divans', 'Fully customizable static and dynamic TSO seat frames (9g, 16g).  Available in FWD/AFT/SIDE facing configurations to meet current TSO-C39 and TSO-C127 requirements.'),
    // Removed due to image rights issue per email 10/24/2019 // new Brochure('LP_2019_Galley_Comp_SS_D3.pdf', 'Galleys', 'Fully customizable, turn-key galley monuments built to meet the unique demands of your interior project.'),
    
    new Brochure('EF_2020_Refridgerator_DS_4_1_2020_digi.pdf', 'Enflite Refrigeration Systems', 'Enflite provides compressor based refrigerators for all types of business jets and VVIP aircraft. Each unit is custom built to fit the existing galley with multiple aesthetic finish options available. The most recent design is for the Embraer Legacy 600 & 650 with several enhancements to allow for added reliability and improved operation.'),
    new Brochure('EF_2020_Warm_Cool_Drawer_DS_4_6_2020_digi.pdf', 'Enflite Warming and Cooling System', 'Warming & Cooling System is designed specifically to keep produce and drinks cool or food warm and ready to serve. The drawer insert provides both heating and cooling functionality with digital display for precise temperature settings.'),
    new Brochure('EF_2019_Galley_SS_FNL_digi.pdf', 'Galley Inserts', 'Custom galley appliance inserts featuring zero clearance and even flow convection ovens, refrigerators, chillers, freezers, and coffee makers.'),
    new Brochure('EF_2019_Lifts_SS_FNL_SEPT19.pdf', 'Monitor Lifts', 'Lift mechanisms and mounts covering various size monitor displays for installation into cabinets, credenzas, side ledges, divan and seat armrests or between bulkheads in both manual and electric operation.'),
    new Brochure('EF_2019_Pocket_Doors_SS_SEPT19.pdf', 'Pocket Doors', 'Custom pocket door mechanisms covering numerous sizes from business jet product lines up to wide body VVIP configurations in both manual and electric operation.'),
    new Brochure('EF_2019_Tables_SS_FNL_digi.pdf', 'Tables', 'Table mechanisms including sidewall tables, in-arm tray tables, hi-lo or fixed height pedestal tables along with commercial business and first class tables in both manual and electric operation.')
  ],
  // 'Bell 407': [
  //   new Brochure('LP_2020_Bell_407_SS_D3.pdf', 'Bell 407 Medical Interior', 'Bell 407 single patient dedicated HEMS medical interior with advanced life support capabilties. Features tracking and swiveling MedDeck that makes loading and unloading large critically ill patients safe and easy.  MedDeck and MedPack are designed for dedicated air ambulance operators serving hospitals and utility ems providers.  Configuration options include: Machined AeroSled stretcher, AeroSled TD for incubators, medical equipment mounts, medical cabinets and MedFloors.  Bel 407 medical  interior is FAA STC’d.')
  // ],
  // 'Bell 412': [
  //   new Brochure('Bell412_Medical_Interior.pdf', 'Bell 412 Medical Interior', 'Bell 412 Multi-Mission HEMS Medical Interior for single and dual patients with advanced life support capabilities.   Features Tracking and Swiveling MedDeck that makes loading and unloading  large critically ill patients safe and easy.  MedDecks and MedPacks are designed for Dedicated HEMS operators and utility ems providers.  Configuration options include: Rescue Stretchers, Cargo Pallets for mounting medical devices, MedFloors that protect HEMS aircraft and are easy to clean.  Bell 412 EMS medical interior is FAA STC’d.')
  // ],
  // 'Cessna Citation Latitude': [
  //   new Brochure('CessnaLC_Medical_Interior.pdf', 'Cessna Citation Latitude Medical Interior', 'Dedicated medical interior using PLUS (Patient Loading Utility System) for Cessna Latitude- Air Ambulance Configurations with single patient and tandem patient ALS (Advanced Life Support) features. Safely and easily load and unload patients and incubator systems.  Accessories include Mission Seats, Wall Mounted Side Arch, Arch with IV Pole, Medical Cabinets, Patient Loading System and Medical Mounts.'),
  // ],
  // 'H130': [
  //   new Brochure('H130_Medical_Interior.pdf', 'H130 Medical Interior', 'H130 Light Weight HEMS medical interior with advanced life support capabilities.   Features Tracking and Swiveling MedDeck that makes loading and unloading large critically ill patients safe and easy.  MedDecks and MedPacks are designed for Dedicated HEMS operators and utility ems providers.  Configuration options include: Cargo Pallets for mounting medical devices, MedFloors that protect HEMS aircraft and are easy to clean. H130 EMS medical interior is FAA STC’d.')
  // ],
  // 'Honda Jet': [
  //   new Brochure('Honda_Jet_LP.pdf', 'Honda Jet', 'LifePort’s custom medical interiors are developed to accommodate the requirements of the most demanding programs. Our medical interiors are built around the Patient Loading Utility System™ (PLUS) which includes an Advanced Life Support base unit, manual loading system and AeroSled™ stretcher. All of our systems can be customized to fit unique mission specifications.')
  // ],
  // 'King Air': [
  //   new Brochure('KingAir_Medical_Interior.pdf', 'King Air Medical Interior', 'PLUS (Patient Loading Utility System) for King Air 90/100/200 and 200 GT- Air Ambulance Configurations with single patient and tandem patient ALS (Advanced Life Support) features. Safely and easily load and unload patients and incubator systems.  Accessories include ServiPlex, Mission Seats, Arch with IV Pole, Medical Cabinets, Powered Loading, Medical Mounts, Patient Loading Devices.')
  // ],
  // 'Pilatus PC-12': [
  //   new Brochure('PC12_Medical_Interior.pdf', 'PC-12 Medical Interior', 'PLUS (Patient Loading Utility System) for Pilatus PC-12 - Air Ambulance Configurations with single patient and tandem patient ALS (Advanced Life Support) features. Safely and easily load and unload patients and incubator systems.  Accessories include ServiPlex, Mission Seats, Arch with IV Pole, Medical Cabinets, Powered Loading, Medical Mounts, Patient Loading Devices.')
  // ],
  // 'UH-60': [
  //   new Brochure('UH_60.pdf', 'UH-60 Mission Equipment', 'Transport configurations to protect and safely move critical patients.')
  // ]
};

for (const acKey in brochureListsByAircraft) {
  if (Array.isArray(brochureListsByAircraft[acKey])) {
    brochureListsByAircraft[acKey] = brochureListsByAircraft[acKey].sort(function(a: Brochure, b: Brochure) {
      return ('' + a.title).localeCompare(b.title);
    });
  }
}

@Component({
  selector: 'app-brochures',
  templateUrl: './brochures.component.html',
  styleUrls: ['./brochures.component.scss']
})
export class BrochuresComponent implements OnInit {

  paramMap: Subscription;
  viewKey: string;
  brochureListsByAircraft = brochureListsByAircraft;
  aircraftKeys = Object.keys(brochureListsByAircraft);

  constructor(
    private connectorService: ConnectorService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('Calling (changeTopImg) from Brochures Component');
      this.connectorService.changeTopImg('brochures');
    });
  }
}



